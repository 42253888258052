// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("admin-lte")
require("bootstrap")
// require("@fortawesome/fontawesome-free")

import "@fortawesome/fontawesome-free/css/all"
import toastr from "toastr/toastr"
import "./application"
import "daterangepicker"
import "datatables.net"

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
})
window.jQuery = $
window.$ = $

require('webpack-jquery-ui');
require('webpack-jquery-ui/css');

toastr.options = {
  closeButton: true,
}

global.select_venue_change = function() {
  let namespace = document.getElementById("venue").value;
  let path = window.location.pathname.split('/').slice(-1, 1);
  let pathParams = window.location.pathname.split('/').slice(2).join('/')
  window.location = `${window.location.origin}/${namespace}${pathParams === '' ? '/users' : '/' + pathParams}`
}

global.toastr = toastr
